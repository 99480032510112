<template>
  <header class="bg-joyful-blue text-white py-6 shadow-lg">
    <div class="container mx-auto px-4">
      <h1 class="text-3xl font-bold animate-bounce-slow">FluencyCheck</h1>

    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>